<template>
  <main-layout itemMenuActive="13" tituloMenu="Cantidad Transacciones">
    <div class="container">
      <br />
      <form v-on:submit.prevent="handleSubmit">
        <div class="columns is-mobile is-multiline is-centered">
          <div class="column is-12">
            <div class="card">
              <div class="card-content">
                <b-field grouped label="Reporte por Cantidad De Transacciones">
                  <b-field label="">
                    <h1 for="" style="margin-right: 1rem; line-height: 2.5rem">
                      Cantidad:
                    </h1>
                    <b-input type="number" v-model="cantidad"></b-input>
                    <b-button native-type="submit" type="is-info"
                      >Consultar</b-button
                    >
                  </b-field>
                </b-field>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div v-if="datosUsuario.length > 0">
        <div
          class="columns is-mobile is-multiline is-centered"
        >
          <div class="column is-12">
            <div class="card">
              <div class="card-content">
                <div class="content">
                  <tabla-detalle-transacciones-usuario
                    :DataInfo="datosUsuario"
                  ></tabla-detalle-transacciones-usuario>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <b-message type="is-info"> Sin datos para procesar. </b-message>
      </div>
    </div>
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="true"
    ></b-loading>
  </main-layout>
</template>
<script>
import MainLayout from "@/components/Layout/MainLayout";
import TablaDetalleTransaccionesUsuario from "@/components/Graficos/TablaDetalleTransaccionesUsuario";
import { mapActions, mapState } from "vuex";

export default {
  name: "CantidadTransaccionesPorUsuario.vue.vue",
  components: { MainLayout, TablaDetalleTransaccionesUsuario },
  data() {
    return {
      isLoading: false,
      cantidad: null,
      datosUsuario: [],
    };
  },
  methods: {
    handleSubmit() {
      
      if (this._PERFIL_MODULO_.onboarding) {
        if (this.cantidad == null) {
          this.alertCustomError(
            "Los campos no deben estar vacíos, debe digitar una cantidad"
          );
          return;
        }

        this.isLoading = true;

        const options = {
          method: "post",
          uri: "boards/cantidadTransacionesPorUsuario",
          data: {
            Cantidad: this.cantidad == null ? 0 : parseInt(this.cantidad)
          },
        };

        this.datosUsuario = [];

        this._axios(options)
          .then((response) => {
            this.datosUsuario = response.data;
          })
          .catch((error) => {
            this.isLoadingTable = false;
            console.log(error);
          })
          .finally(() => this.cancelarLoading());
      } else {
        this.$router.push("login");
      }
    },
    alertCustomError(msj, titulo = "Error", tipo = "is-danger") {
      this.$buefy.dialog.alert({
        title: titulo,
        message: msj,
        type: tipo,
        hasIcon: true,
        icon: "times-circle",
        iconPack: "fa",
        ariaRole: "alertdialog",
        ariaModal: true,
      });
    },
    cancelarLoading() {
      setTimeout(() => {
        this.isLoading = false;
      }, 1500);
    },
    ...mapActions(["_axios"]),
  },
  computed: {
    ...mapState(["_PERFIL_MODULO_"]),
  },
};
</script>