<template>
  <b-table
    :data="dataTable"
    :paginated="isPaginated"
    :per-page="perPage"
    :pagination-rounded="isPaginationRounded"
  >
    <b-table-column field="counter" label="Nro." width="20" numeric v-slot="props">
      {{ props.row.counter }}
    </b-table-column>

    <b-table-column field="id" label="ID" width="20" numeric v-slot="props">
      {{ props.row.id }}
    </b-table-column>

    <b-table-column field="id" label="Guid" width="20" v-slot="props">
      {{ props.row.transactionid }}
    </b-table-column>

    <b-table-column field="fecha" label="Fecha" width="110" v-slot="props">
      {{ props.row.fecha }}
    </b-table-column>
    <b-table-column field="hora" label="Hora" v-slot="props">
      {{ props.row.hora }}
    </b-table-column>
    <b-table-column
      field="identificacion"
      label="Identificacion"
      v-slot="props"
    >
      {{ props.row.identificacion }}
    </b-table-column>
    <b-table-column field="usuario" label="Nombre" v-slot="props">
      {{ props.row.usuario }}
    </b-table-column>
    <b-table-column field="genero" label="Genero" v-slot="props">
      {{ props.row.genero }}
    </b-table-column>
    <b-table-column field="score" label="Score" v-slot="props">
      {{ props.row.score }}
    </b-table-column>
    <b-table-column
      field="scoreconfigured"
      label="Score Configured"
      v-slot="props"
    >
      {{ props.row.scoreconfigured }}
    </b-table-column>
    <b-table-column field="autorizado" label="Autorizado" v-slot="props">
      {{ props.row.autorizado }}
    </b-table-column>
    <b-table-column field="estado" label="Estado" v-slot="props">
      {{ props.row.estado }}
    </b-table-column>
  </b-table>
</template>

<script>
export default {
  name: "TablaDetalleTransaccionesUsuario",
  props: ["DataInfo"],
  data() {
    return {
      dataTable: [],
      counter: 0,
      isPaginated: true,
      isPaginationRounded: true,
      perPage: 10,
    };
  },
  mounted() {
    this.cargarDatos();
  },
  methods: {
    cargarDatos() {
      this.DataInfo.forEach((item) => {
        this.counter++;
        item.counter = this.counter;
        this.dataTable.push(item);
      });
    },
  },
};
</script>

<style>
:root {
  --primary: #00522a;
}

.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selected {
  background-color: #00522a;
  color: #fff;
}

.pagination-link.is-current {
  background-color: #00522a;
  border-color: #00522a;
  color: #fff;
}

.tag:not(body).is-primary {
  background-color: #00522a;
  color: #fff;
}
.has-text-primary {
  color: #00522a !important;
}
.styled-select select {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background: #00522a;
}
.select select:focus,
.taginput .taginput-container.is-focusable:focus,
.textarea:focus,
.input:focus,
.select select.is-focused,
.taginput .is-focused.taginput-container.is-focusable,
.is-focused.textarea,
.is-focused.input,
.select select:active,
.taginput .taginput-container.is-focusable:active,
.textarea:active,
.input:active,
.select select.is-active,
.taginput .is-active.taginput-container.is-focusable,
.is-active.textarea,
.is-active.input {
  border-color: #00522a;
}
.datepicker .dropdown .input[readonly]:focus,
.datepicker .dropdown .input[readonly].is-focused,
.datepicker .dropdown .input[readonly]:active,
.datepicker .dropdown .input[readonly].is-active,
.datepicker .dropdown-trigger .input[readonly]:focus,
.datepicker .dropdown-trigger .input[readonly].is-focused,
.datepicker .dropdown-trigger .input[readonly]:active,
.datepicker .dropdown-trigger .input[readonly].is-active {
  box-shadow: none;
}

.pagination-previous:focus,
.pagination-next:focus,
.pagination-link:focus {
  border-color: #00522a;
}

.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-today {
  border: solid 1px rgba(0, 82, 42, 1);
}
</style>